export const meta: BlockMeta = {
  editMode: "both",
  title: "Section Heading",
  description: "",
  keywords: ["heading", "header"],
  // @ts-ignore
  icon: "heading",
  cache: false,
  tags: ["root"],
  conditions: {
    multiple: true,
  },
}

import { defineBlock, EditableText } from "eddev/blocks"
import { useAdjustedHeight } from "@hooks/useAdjustedHeight"
import { Eyebrow } from "@components/atoms/Eyebrow"
import { GridLines } from "@components/atoms/GridLines"

export default defineBlock("content/section-heading", (props) => {
  const { addRef } = useAdjustedHeight()

  return (
    <div
      ref={(el) => (props.gridlines ? el && addRef(el) : undefined)}
      className={`subtheme-${props.scheme} w-full relative bg-bg`}
    >
      {props.gridlines && <GridLines />}
      <div className="grid-auto items-center pt-[80px] pb-8 lg:max-w-[1280px] xl:max-w-[1440px]">
        <div className="col-span-12 flex flex-col gap-5 items-start px-5 relative z-10">
          {props.useEyebrow && (
            <>
              <div className="hidden md:block">
                <Eyebrow size="m" style="primary" filled icon={props.eyebrowIcon ?? undefined}>
                  {props.eyebrowText ?? ""}
                </Eyebrow>
              </div>
              <div className="md:hidden">
                <Eyebrow size="l" style="primary" filled icon={props.eyebrowIcon ?? undefined}>
                  {props.eyebrowText ?? ""}
                </Eyebrow>
              </div>
            </>
          )}

          <EditableText
            className="type-title-l text-text text-left w-full md:w-3/5 lg:w-1/2"
            store="title"
            placeholder="Enter title..."
          />

          <EditableText
            className="type-body-xl text-text text-left w-full md:w-3/5 lg:w-1/2"
            store="content"
            placeholder="Enter content..."
          />
        </div>
      </div>
    </div>
  )
})
